import axios from "axios";
import { Plan } from "../models/PlanModel";
import { MaxNutritionRequest } from "../models/MaxNutritionRequest";
import { OrderedTemplate } from "../models/OrderedTemplate";
import { EnterpriseDetails } from "../models/EnterpriseDetails";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/MaxNutritionService";

export const createPlanOrder = async (userProfileData) => {
  const orderedTemplateInstance = new OrderedTemplate(
    process.env.REACT_APP_STRIPE_TEMPLATE_ID, // TemplateId
    "MaxNutrition Plan", // Title
    "MaxNutrition Plan Description" // Description
  );

  // Crearea instanței NutritionRequest
  const nutritionRequestInstance = new MaxNutritionRequest(
    userProfileData.gender.toString(),
    userProfileData.age.toString(),
    userProfileData.height.toString(),
    userProfileData.weight.toString(),
    userProfileData.nutritionObjective.toString(),
    userProfileData.activityLevel.toString(),
    userProfileData.fullName.toString(),
    userProfileData.email.toString(),
    userProfileData.favoriteFoods,
    userProfileData.healthConcerns.toString(),
    ''
  );

  const enterpriseDetials = new EnterpriseDetails(
    userProfileData.affiliateCode,
    process.env.REACT_APP_APP_TYPE,
    process.env.REACT_APP_APP_NAME
  );

  const planInstance = new Plan(
    process.env.REACT_APP_DOMAIN, // webdomain
    enterpriseDetials,
    true, // acceptedTermsAndConditions
    orderedTemplateInstance,
    nutritionRequestInstance
  );

  try {
    const response = await axios.post(`${API_URL}/PlaceOrder`, planInstance, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    const checkoutUrl = response.data.url;

    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    } else {
      console.error("URL-ul de checkout nu este disponibil.");
    }

  } catch (error) {
    console.error("Eroare la crearea comenzii:", error);
    throw error;
  }
};


export const CheckoutSuccess = async (sessionId) => {
  try {
    const response = await axios.get(`${API_URL}/Success`, {
      params: { sessionId },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
