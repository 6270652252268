export class MaxNutritionRequest {
  constructor(
    gender,
    age,
    height,
    weight,
    nutritionObjective,
    activityLevel,
    fullName,
    email,
    favoriteFoods,
    healthConcerns,
    prompt
  ) {
    this.Gender= gender;
    this.Age= age;
    this.Height= height;
    this.Weight= weight;
    this.NutritionObjective= nutritionObjective;
    this.ActivityLevel= activityLevel;
    this.FavoriteFoods= favoriteFoods.join(", ") ;
    this.FullName= fullName;
    this.Email= email;
    this.HealthConcerns= healthConcerns;
    this.prompt = prompt;


    
  }
}
