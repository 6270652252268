import { MaxNutritionRequest } from "./MaxNutritionRequest";
import { OrderedTemplate } from "./OrderedTemplate";
import { ProductModel } from "./ProductModel";
import { EnterpriseDetails } from "./EnterpriseDetails";

export class Plan {
  constructor(
    webdomain,
    enterpriseDetails,
    acceptedTermsAndConditions,
    orderedTemplate,
    nutritionRequest
  ) {

    this.Webdomain = webdomain;
    this.EnterpriseDetails = enterpriseDetails;
    this.AcceptedTermsAndConditions = acceptedTermsAndConditions;

    var orderedTemplate = new OrderedTemplate(
      orderedTemplate.TemplateId,
      orderedTemplate.Title,
      orderedTemplate.Description
    );
    var nutritionRequest = nutritionRequest;
    this.Product = new ProductModel(nutritionRequest, orderedTemplate);
  }
}
