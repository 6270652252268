export const questions = [
  {
    id: "fullName",
    label: "Numele complet",
    type: "text",
    placeholder: "Numele tău complet",
    regex: /^[a-zA-Z\s]+$/,
    errorMessage: "Numele este invalid",
  },
  {
    id: "age",
    label: "Vârsta",
    type: "number",
    placeholder: "Vârsta ta",
    regex: /^\d+$/,
    errorMessage: "Vârsta este invalidă",
  },
  {
    id: "height",
    label: "Inâltimea",
    type: "number",
    placeholder: "Inâltimea",
    regex: /^\d+$/,
    errorMessage: "Inâltimea este invalidă",
  },
  {
    id: "weight",
    label: "Greutatea",
    type: "number",
    placeholder: "Greutatea ta",
    regex: /^\d+$/,
    errorMessage: "Greutatea este invalidă",
  },
  {
    id: "gender",
    label: "Sexul",
    type: "checkboxsingle",
    options: [
      {
        label: "Masculin",
        value: "male",
        image: "./../assets/questions/man.png",
      },
      {
        label: "Feminin",
        value: "female",
        image: "./../assets/questions/woman.png",
      },
      {
        label: "Prefer să nu spun",
        value: "prefer_not_to_say",
        image: "./../assets/questions/nosex.png",
      },
    ],
  },
  {
    id: "nutritionObjective",
    label: "Scopul nutrițional principal",
    type: "select",
    options: [
      { label: "Pierdere în greutate", value: "weight_loss" },
      { label: "Menținere", value: "maintenance" },
      { label: "Creștere în masă musculară", value: "muscle_gain" },
      {
        label: "Îmbunătățirea sănătății generale",
        value: "health_improvement",
      },
    ],
  },
  {
    id: "activityLevel",
    label: "Nivelul de activitate fizică",
    type: "select",
    options: [
      { label: "Sedentar", value: "sedentary" },
      { label: "Ușor activ", value: "lightly_active" },
      { label: "Moderat activ", value: "moderately_active" },
      { label: "Foarte activ", value: "very_active" },
    ],
  },
  {
    id: "dietaryRestrictions",
    label: "Restricții alimentare",
    type: "checkbox",
    options: [
      {
        label: "Meniu Normal",
        value: "normal meal",
        image: "./../assets/questions/normal_meal.png",
      },
      {
        label: "Vegetarian",
        value: "vegetarian",
        image: "./../assets/questions/vegetarian.png",
      },
      {
        label: "Vegan",
        value: "vegan",
        image: "./../assets/questions/vegan.png",
      },
      {
        label: "Fără lactoză",
        value: "lactose_free",
        image: "./../assets/questions/free-lactose.png",
      },
      {
        label: "Fără gluten",
        value: "gluten_free",
        image: "./../assets/questions/gluten-free.png",
      },
      {
        label: "Alte alergii",
        value: "other_allergies",
        image: "./../assets/questions/other-alergens.png",
      },
    ],
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "email@example.com",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: "Adresa de email este invalidă",
  },
  {
    id: "favoriteFoods",
    label: "Ce alimente îți plac?",
    type: "checkbox",
    options: [
      {
        label: "Oua",
        value: "eggs",
        image: "./../assets/questions/foods/egg.png",
      },
      {
        label: "Carne Slaba",
        value: "lean-meat",
        image: "./../assets/questions/foods/chicken.png",
      },
      {
        label: "Produse Lactate",
        value: "dairy-products",
        image: "./../assets/questions/foods/dairy.png",
      },
      {
        label: "Somon",
        value: "somon-fish",
        image: "./../assets/questions/foods/somon.png",
      },
      {
        label: "Avocado",
        value: "avocado",
        image: "./../assets/questions/foods/avocado.png",
      },
      {
        label: "Quinoa",
        value: "quinoa",
        image: "./../assets/questions/foods/quinoa.png",
      },
      {
        label: "Broccoli",
        value: "broccoli",
        image: "./../assets/questions/foods/broccoli.png",
      },
      {
        label: "Mazare",
        value: "green-peas",
        image: "./../assets/questions/foods/green-peas.png",
      },
      {
        label: "Cereale integrale",
        value: "whole-grains",
        image: "./../assets/questions/foods/whole-grains.png",
      },
    ],
  },
  {
    id: "healthConcerns",
    label: "Probleme de sănătate specifice",
    type: "text",
    placeholder: "Specificați dacă există",
  },
];
